import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"
import Swal from 'sweetalert2';

export default class User {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                        userRetrieve(id: ${id}) {
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          groups{
                            id
                            name
                          }
                          city{
                            id
                            name
                            state{
                                id
                                name
                            }
                          }  
                          profile{
                            id
                            gamer
                            winner
                          }
                        }
                      }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.userRetrieve
            ) {
                response.data.data.userRetrieve.compressed = {
                    groups: response.data.data.userRetrieve.groups,
                };
            }

            response.data.data.userRetrieve.groups = response.data.data.userRetrieve?.groups?.id

            return response.data.data.userRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                userList{
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          groups{
                            id
                            name
                          }
                          city{
                            id
                            name
                            state{
                                id
                                name
                            }
                          }  
                          profile{
                            id
                            gamer
                            winner
                          }
                }
              }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].groups = usersList[i].groups.map(node => node.id);
        //     usersList[i].groups = usersList[i].groups.id;
        // }
        return response.data.data?.userList;
    }


    static async gamer(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                userGamerList{
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          groups{
                            id
                            name
                          }
                          city{
                            id
                            name
                            state{
                                id
                                name
                            }
                          }  
                          profile{
                            id
                            gamer
                            winner
                          }
                }
              }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].groups = usersList[i].groups.map(node => node.id);
        //     usersList[i].groups = usersList[i].groups.id;
        // }
        return response.data.data?.userGamerList;
    }

    static async winner(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  userWinnerList{
                    id
                    email
                    username
                    firstName
                    lastName
                    phone
                    prizeDeliveryStatus
                    deliveryLog
                    city{
                        id
                        name
                    }
                    premio{
                      id
                      name
                      state{
                        id
                        name
                        cities{
                          id
                          name
                          state{
                            id
                            name
                          }
                        }
                      }                       
                    }
                    groups{
                      id
                      name
                    }
                    profile{
                      id
                      avatar
                      gamer
                      winner
                      progress{
                        id
                        game{
                          id
                          name
                        }
                      }
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].groups = usersList[i].groups.map(node => node.id);
        //     usersList[i].groups = usersList[i].groups.id;
        // }
        return response.data?.data?.userWinnerList;
    }


    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteUser(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteUser.ok;
    }

    static async create(
        axios: AxiosInstance,
        password: string,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        groups: number,
        city: number | null,
        phone: string,
    ) {
        const cityField = city ? `city: ${city},` : ""; // Solo incluir si city tiene valor
        const groupsField = groups ? `groups: ${groups},` : ""; // Solo incluir si groups tiene valor

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    createUser (
                    newCustomuser: {
                      password: "${password}",
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      ${groupsField}
                      ${cityField}
                      phone: "${phone}",
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createUser.ok) {
                await StateStore.actions.actionsSetNewIdUser(response.data.data.createUser.customuser.id);
                return response.data.data.createUser.ok;
            } else if (response.data.data.createUser.errors) {
                const errores = response.data.data.createUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        groups: number,
        city: number | null,
        phone: string,
    ) {
        const cityField = city ? `city: ${city},` : ""; // Solo incluir si city tiene valor
        const groupsField = groups ? `groups: ${groups},` : ""; // Solo incluir si groups tiene valor

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id},
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      ${groupsField}
                      ${cityField}
                      phone: "${phone}",                     
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateUser.ok) {
                return response.data.data.updateUser.ok;
            } else if (response.data.data.updateUser.errors) {
                const errores = response.data.data.updateUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async editP(
        axios: AxiosInstance,
        id: number,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        groups: number,
        city: number | null,
        phone: string,
        password: string
    ) {
        const cityField = city ? `city: ${city},` : ""; // Solo incluir si city tiene valor
        const groupsField = groups ? `groups: ${groups},` : ""; // Solo incluir si groups tiene valor

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id},
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      ${groupsField}
                      ${cityField}
                      phone: "${phone}",
                      password: "${password}"
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateUser.ok) {
                return response.data.data.updateUser.ok;
            } else if (response.data.data.updateUser.errors) {
                const errores = response.data.data.updateUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async changePassword(
        axios: AxiosInstance,
        oldPassword: string,
        newPassword1: string,
        newPassword2: string
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation {
            passwordChange(
                oldPassword: "${oldPassword}",
                newPassword1: "${newPassword1}",
                newPassword2: "${newPassword2}"
            ) {
                success
                errors
            }
        }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });

        if (response && response.data) {
            if (response.data.data.passwordChange.success) {
                Swal.fire({
                    icon: "success",
                    title: "Contraseña cambiada",
                    text: "Tu contraseña ha sido cambiada exitosamente.",
                });
                return response.data.data.passwordChange.ok;
            } else if (response.data.data.passwordChange.errors) {
                const errores = response.data.data.passwordChange.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                StateStore.actions.actionsSetErrorMessage(mensajes);
                const errorMessage = errores && errores.oldPassword && errores.oldPassword.length > 0 && errores.oldPassword[0]?.message === "Invalid password." ? "La contraseña actual es incorrecta." : errores && errores.newPassword2 && errores.newPassword2.length > 0 && errores.newPassword2[0]?.message ===
                    "This password is too common." ? "La contraseña es demasiado común. Utilice letras números y caracteres especiales." : "Hubo un problema al cambiar la contraseña."

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: errorMessage,
                });
                return null;
            } else if (response.data.errors) {
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un problema al cambiar la contraseña.",
                });
                return null;
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un problema al cambiar la contraseña.",
            });
            return null;
        }
    }


    static async updatePrizeDeliveryStatus(
        axios: AxiosInstance,
        id: number,
        prizeDeliveryStatus: string,
    ) {
        const currentUser = StateStore.getters.getCurrentUser()
        const response = await axios?.post(
            "/graphql/",
            `mutation {
                updatePrizeDeliveryStatus(
                    id: ${id}, 
                    prizeDeliveryStatus: "${prizeDeliveryStatus}"
                    requestUserId: ${currentUser?.id}
                ) {
                    success
                    message
                    prizeDeliveryStatus
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updatePrizeDeliveryStatus?.ok) {
                return response.data.data.updatePrizeDeliveryStatus.ok;
            } else if (response.data.data.updatePrizeDeliveryStatus?.errors) {
                const errores = response.data.data.updatePrizeDeliveryStatus.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            StateStore.actions.actionsSetErrorMessage(["Error desconocido al realizar la solicitud."]);
            return null;
        }
    }

}
