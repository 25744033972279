import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const api = axios.create({
    baseURL: 'https://cesptws.idooprod.com'
});

api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token: string | null = localStorage.getItem("token");
        if (token !== null && token !== 'null' && token !== 'null-new') {
            config.headers["Authorization"] = `JWT ${token}`;
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

export default api;