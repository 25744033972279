<template>
  <div class="row justify-content-center main-container" :data-value="getToken">
    <div
      class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 h-100 sec-container"
      :data-value="getErrorMessage"
    >
      <div class="card border-0 rounded-0 bg-white authentication-card w-100">
        <div class="letter-spacing h-100" style="padding: 4vh">
          <div class="mb-10 icon">
            <img
              src="../../../assets/images/favicon.png"
              alt="logo-icon"
              style="height: 60px; width: 60px"
            />
          </div>
          <h5 class="text-black fw-bold mb-15 text-center">
            Entrar a su Cuenta!
          </h5>

          <form style="display: flex; flex-direction: column; padding: 0 20px">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nombre de Usuario
              </label>
              <input
                class="form-control shadow-none rounded-0 text-black"
                id="username"
                v-model="username"
                type="text"
                placeholder="Nombre de Usuario"
                name="username"
                @input="validateUsername"
                required
                autocomplete="off"
              />
              <p v-if="errors.username" class="text-danger">
                {{ errors.username }}
              </p>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Contraseña
              </label>
              <div class="password-input">
                <input
                  class="form-control shadow-none rounded-0 text-black"
                  id="password"
                  v-model="password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="Contraseña"
                  name="password"
                  required
                  @input="validatePassword"
                  @keydown.enter="submit"
                  autocomplete="off"
                  style="padding-right: 2.5rem"
                />
                <button
                  type="button"
                  class="toggle-visibility"
                  @click="togglePasswordVisibility"
                >
                  <img
                    class="input-right-icon"
                    :src="
                      isPasswordVisible
                        ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                        : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                    "
                    alt="Toggle Password Visibility"
                    width="25"
                    height="25"
                    style="filter: invert(0.4)"
                  />
                </button>
              </div>
              <p v-if="errors.password" class="text-danger">
                {{ errors.password }}
              </p>
            </div>
            <div class="form-group">
              <div ref="recaptcha" class="recaptcha-container"></div>
              <p v-if="errors.captcha" class="text-danger">
                {{ errors.captcha }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20 options-container"
            >
              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 form-check-container"
              >
                <input
                  class="form-check-input shadow-none check"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Recordarme
                </label>
              </div>
              <a
                href="https://cesptws.idooprod.com/password-reset"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary forgotten-password"
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿Olvidaste tu Contraseña?
              </a>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
              @keydown.enter="submit"
              @click.prevent="submit"
            >
              Entrar
            </button>
            <div v-if="error !== ''" class="alert bg-danger alert-container">
              <label
                type="error"
                :value="alert"
                class="alert-warning text-bold"
              >
                {{ error }}
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";
import router from "@/router";

export default {
  name: "LoginForm",
  data() {
    return {
      loading: false,
      alert: false,
      reCaptchaLoading: false,
      isPasswordVisible: false,
      password: null,
      username: null,
      error: null,
      errors: {
        password: "",
        username: "",
        captcha: "",
      },
      recaptchaToken: "",
      captchaSiteKey:"6LdpTnkqAAAAAN4Elg_c17Fj4ZXa2lNGvP-YWamr",
      captchaLoaded: false,
      currentUser: null,
    };
  },
  mounted() {
    // Inicializa el reCAPTCHA después de que el componente se monte
    this.loadRecaptcha();
  },
  methods: {
    validatePassword() {
      if (this.password) {
        this.errors.password =
          this.password?.length < 7
            ? "La contraseña debe tener al menos 8 caracteres."
            : "";
      } else {
        this.errors.password = "La contraseña es requerida";
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    validateUsername() {
      if (!this.username) {
        this.errors.username = "Nombre de usuario requerido";
      }
    },
    loadRecaptcha() {
      if (window.grecaptcha && typeof window.grecaptcha.render === "function") {
        window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.captchaSiteKey,
          callback: this.onCaptchaResponse,
        });
        this.reCaptchaLoading = false; // Resetear el indicador si se carga correctamente
      } else {
        console.warn(
          "reCAPTCHA no se ha cargado correctamente. Reintentando en 1 segundo..."
        );
        this.reCaptchaLoading = true; // Activar el indicador
        setTimeout(() => {
          this.reCaptchaLoading = false; // Resetear el indicador después de 1 segundo
          this.loadRecaptcha(); // Intentar cargar de nuevo
        }, 1000);
      }
    },
    onCaptchaResponse(response) {
      // Manejar el token recibido de reCAPTCHA
      if (response) {
        this.recaptchaToken = response;
        this.errors.captcha = "";
      } else {
        this.errors.captcha = "Por favor, verifica el reCAPTCHA.";
      }
    },
    async getTokenAfterLogin() {
      let token = localStorage.getItem("token");
      if (
        token !== null &&
        token !== "null" &&
        token !== "null-new" &&
        token !== undefined &&
        token !== "undefined"
      ) {
        await StateStore.actions.actionsMe({ axios: this.$axios });
        await StateStore.actions.actionsCurrentUser({ axios: this.$axios });
        this.currentUser = StateStore.getters.getCurrentUser();
        // Entrar a la lista de ganadores cuando el rol es "Operador"
        if (
          (this.currentUser?.groups &&
            this.currentUser.groups.length > 0 &&
            this.currentUser.groups[0].id == 2) ||
          (this.currentUser?.compressed?.groups &&
            this.currentUser.compressed?.groups.length > 0 &&
            this.currentUser.compressed?.groups[0].id == 2)
        ) {
          router.push("/games-winner-list");
          // Entrar al dashboard cuando el rol es "Super Administrador"
        } else if (
          (this.currentUser?.groups &&
            this.currentUser.groups.length > 0 &&
            this.currentUser.groups[0].id == 1) ||
          (this.currentUser?.compressed?.groups &&
            this.currentUser.compressed?.groups.length > 0 &&
            this.currentUser.compressed?.groups[0].id == 1)
        ) {
          router.push("/");
          // No Pasar del login si el rol no es ni "Operador" ni "Super Administrador"
        } else {
          router.push("/login");
        }
      } else {
        this.username = "";
        this.password = "";
        this.loading = false;
      }
      return token;
    },
    async submit() {
      this.validatePassword();
      this.validateUsername();
      this.errors.captcha = "";

      // Verificar si reCAPTCHA está marcado y tiene un token válido
      if (!this.recaptchaToken) {
        this.errors.captcha = "Por favor, verifica el reCAPTCHA.";
        return; // Detén el submit si el reCAPTCHA no está verificado
      }

      if (!this.errors.password && !this.errors.username) {
        try {
          // Verificar el reCAPTCHA en el backend
          const isCaptchaValid = await this.verifyCaptcha();
          if (!isCaptchaValid) {
            this.errors.captcha = "El reCAPTCHA no es válido.";
            grecaptcha.reset();
            this.loading = false;
            return;
          }

          // Realizar el login si el reCAPTCHA es válido
          await StateStore.actions
            .actionsLogin({
              axios: this.$axios,
              username: this.username,
              password: this.password,
            })
            .then(async () => await this.getTokenAfterLogin());
        } catch (error) {
          this.error = "Hubo un error al iniciar sesión.";
          grecaptcha.reset();
        }
      } else {
        this.loading = false;
        grecaptcha.reset();
      }
    },
    async verifyCaptcha() {
      // Verificar el token de reCAPTCHA
      try {
        const response = await this.$axios.post("/verify-recaptcha/", {
          recaptcha_token: this.recaptchaToken,
        });
        return response.data.success;
      } catch (error) {
        return false;
      }
    },
  },
  computed: {
    getErrorMessage() {
      if (stateStore.errorMessage === "Please, enter valid credentials.") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = "Usuario o contraseña incorrectos.";
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = stateStore.errorMessage; // Asigna el mensaje de error directamente
      }
      if (this.error) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.alert = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false;
        grecaptcha.reset();
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false;
      }
      return this.error;
    },
    getToken() {
      let token = localStorage.getItem("token");
      if (
        token !== null &&
        token !== "null" &&
        token !== "null-new" &&
        token !== undefined &&
        token !== "undefined"
      ) {
        StateStore.actions.actionsMe({ axios: this.$axios });
        StateStore.actions.actionsCurrentUser({ axios: this.$axios });
        // console.log("aca estoy...");
        router.push("/");
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.username = "";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.password = "";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false;
      }
      return token;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .authentication-card {
    max-height: 98vh;
    padding: 2vh;
  }
  img {
    height: 50px;
    width: 50px;
  }
}

.main-container {
  margin-left: -275px;
  margin-top: -125px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.sec-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  text-align: center;
}

.form-control,
.default-btn,
.alert-container {
  height: 45px;
}

.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input .toggle-visibility {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

.forgotten-password {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

.recaptcha-container {
  transform: scale(0.8);
  transform-origin: 0 0;
}

.options-container {
  z-index: 1;
  padding: 5px 0;
}

.form-check-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check {
  cursor: pointer;
}

.default-btn {
  line-height: normal;
  z-index: 1;
}

.alert-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
</style>
