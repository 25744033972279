<template>
  <div
    ref="mymodal"
    class="modal fade createNewModal"
    id="createNewGameModal"
    tabindex="-1"
    aria-hidden="true"
    @click.self="handleBackdropClick"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Juego</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form>
            <div
              class="row d-flex justify-content-between align-items-start w-100"
            >
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-15 mb-md-25">
                  <label class="text-black fw-semibold mb-10">Nombre</label>
                  <input
                    v-model="item.name"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Juego 1"
                    :required="true"
                  />
                </div>
              </div>

              <!-- Checkbox para el juego por defecto -->
              <div class="col-lg-6 col-md-6 d-flex justify-content-end mt-10">
                <div
                  class="form-group mb-15 mb-md-25 d-flex align-items-center"
                >
                  <input
                    v-model="item.default"
                    type="checkbox"
                    id="defaultGame"
                    class="form-check-input me-2"
                  />
                  <label
                    for="defaultGame"
                    class="form-check-label text-black fw-semibold"
                  >
                    Juego predeterminado
                  </label>
                </div>
              </div>
            </div>

            <div v-if="error !== '' || showError">
              <div class="row" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <button
                class="text-white btn-danger transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </button>
              <button
                class="btn-success transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                type="submit"
                @click.prevent="submitForm"
              >
                {{ title }} Juego
              </button>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";

export default {
  name: "CreateNewGameModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      showError: false,
      maxLen: 10,
      minLen: 8,
      item: {
        name: "",
        default: false,
      },
    };
  },
  watch: {
    async id(newVal) {
      // console.log('newVal: ', newVal)
      if (newVal > 0) {
        await StateStore.actions.actionsGetGame({
          axios: this.axios,
          id: newVal,
        });
        this.item = await StateStore.getters.getGameRetrieve();
        // console.log("itemRetrieve: ", this.item);
      } else if (newVal === undefined) {
        this.item = [];
      }
    },
  },
  mounted() {
    this.$refs.mymodal.addEventListener(
      "hidden.bs.modal",
      this.handleModalHidden
    );
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener(
      "hidden.bs.modal",
      this.handleModalHidden
    );
  },
  methods: {
    validatePassword() {
      if (this.password !== this.password2) {
        this.showError = true;
        this.error = "Las contraseñas no coinciden";
        this.alert = true;
        // console.log("password don't match");
      } else {
        this.error = "";
        this.alert = false;
        this.showError = false;
        // console.log("password match");
      }
    },
    checkMinLength(field, minLen) {
      if (
        field === "phone" &&
        this.item[field] &&
        this.item[field].toString()?.length < minLen
      ) {
        this.error = `El campo debe tener más de ${
          minLen ? minLen : this.minLen
        } caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = "";
        }, 5000);
      } else if (
        this.item[field] &&
        this.item[field]?.length < (minLen ? minLen : this.minLen)
      ) {
        this.error = `El campo debe tener más de ${
          minLen ? minLen : this.minLen
        } caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === "phone") {
        if (isNaN(this.item[field])) {
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if (
          this.item &&
          this.item[field] &&
          this.item[field].toString()?.length > maxLen
        ) {
          this.error = `El campo no puede tener más de ${
            maxLen ? maxLen : this.maxLen
          } caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = "";
        }, 5000);
      } else if (
        this.item &&
        this.item[field] &&
        this.item[field]?.length >= (maxLen ? maxLen : this.maxLen)
      ) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${
          maxLen ? maxLen : this.maxLen
        } caracteres`;
        // console.log("error: ", field);
        // console.log("error: ", this.error);
        // console.log("error: ", this.showError);
      } else {
        this.showError = false;
        this.error = "";
      }
    },
    handleModalHidden() {
      this.$emit("modal-closed");
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    async submitForm() {
      if (!this.item.name) {
        this.showError = true;
        this.error = "El juego debe tener un nombre";
      } else {
        this.showError = false;
        this.error = "";
        if (this.id === undefined) {
          // console.log('this.id: ', this.id);
          await StateStore.actions
            .actionsCreateGame({
              axios: this.$axios,
              name: this.item.name,
              default: this.item.default,
            })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                // Maneja el error
                console.error("Error al crear el juego:", response);
              }
            })
            .catch((error) => {
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error("Error al crear el usuario:", error);
            });
        } else {
          await StateStore.actions
            .actionsEditGame({
              axios: this.$axios,
              id: this.item.id,
              name: this.item.name,
              default: this.item.default,
            })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                // Maneja el error
                console.error("Error al editar el juego:", response);
              }
            })
            .catch((error) => {
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error("Error al editar el game:", error);
            });
        }
      }
    },
  },
};
</script>
<style scoped>
.form-group {
  position: relative;
}

.form-control {
  padding-right: 40px;
  /* Espacio para el botón */
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  /* Ajusta esto según sea necesario */
}

.btn-danger {
  background-color: crimson;
}

.btn-success {
  background-color: #008000;
}

.d-block {
  color: #ffffff !important;
}
</style>
