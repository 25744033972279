<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <!--      <div class="d-sm-flex align-items-center">-->
      <!--        <a-->
      <!--          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"-->
      <!--          href="#"-->
      <!--          data-bs-toggle="modal"-->
      <!--          data-bs-target="#createNewUserModal"-->
      <!--          @click.prevent="handleModalClosed"-->
      <!--        >-->
      <!--          Agregar Jugador-->
      <!--          <i class="flaticon-plus position-relative ms-5 fs-12"></i>-->
      <!--        </a>-->
      <!--      </div>-->
      <div
        class="d-flex align-items-center justify-content-end"
        style="width: 100%"
      >
        <form
          class="search-box position-relative me-15"
          @submit.prevent="filterResults"
        >
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            v-model="searchQuery"
            placeholder="Buscar Jugador"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Email
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nombre
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Apellidos
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Juego
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Estado del Kit de Premio
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Registro de entrega
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in paginatedItems" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary long-text">
                {{ user?.email }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-black-emphasis long-text"
              >
                {{ user?.firstName }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted long-text">
                {{ user?.lastName }}
              </td>
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis long-text"
              >
                      {{
                        user?.profile?.progress?.length > 0
                          ? user.profile.progress[0].game?.name
                          : ""
                      }}
              </th>
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <span
                  v-if="user?.premio && user.premio.length === 0"
                  class="ms-20"
                  >No hay kit asignado</span
                >
                <div
                  v-if="
                    (this.currentUser?.groups &&
                      this.currentUser.groups.length > 0 &&
                      this.currentUser.groups[0].id == 2) ||
                    (this.currentUser?.compressed?.groups &&
                      this.currentUser.compressed?.groups.length > 0 &&
                      this.currentUser.compressed?.groups[0].id == 2 &&
                      user.prizeDeliveryStatus?.toLowerCase() === 'entregado')
                  "
                >
                  <span class="text-success ms-20">Entregado</span>
                </div>
                <div v-else class="d-flex align-items-center ms-15">
                  <div
                    class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text"
                  >
                    <select
                      v-if="user?.premio && user.premio.length > 0"
                      :value="user.prizeDeliveryStatus?.toLowerCase() || ''"
                      @change="
                        updatePrizeDeliveryStatus(user, $event.target.value)
                      "
                      class="form-select form-select-sm custom-select"
                      :class="{
                        'text-success':
                          user.prizeDeliveryStatus?.toLowerCase() ===
                          'entregado',
                        'text-danger':
                          user.prizeDeliveryStatus?.toLowerCase() ===
                          'pendiente',
                      }"
                    >
                      <option value="entregado">Entregado</option>
                      <option value="pendiente">Pendiente</option>
                    </select>
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary long-text">
                <div
                  v-if="
                    user.prizeDeliveryStatus?.toLowerCase() === 'entregado' &&
                    user?.deliveryLog
                  "
                >
                  <span
                    style="
                      color: #4c4a68;
                      display: inline-block;
                      margin-bottom: 5px;
                    "
                    >Usuario: {{ user.deliveryLog.split(" - ")[0] }}</span
                  ><br />
                  <small class="text-muted"
                    >Fecha:
                    {{ formatDate(user.deliveryLog.split(" - ")[1]) }}</small
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="pagination-area d-md-flex mb-25 justify-content-between align-items-center"
    >
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems?.length }}</span> de
        <span class="fw-bold">{{ items?.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="goToPreviousPage"
              aria-label="Previous"
            >
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="goToNextPage"
              aria-label="Next"
            >
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div v-if="loading" class="spinner-container">
    <div class="spinner"></div>
  </div>
  <CreateNewUserModal
    :axios="axios"
    :id="selectedItem?.id"
    :no-close-on-backdrop="true"
    :title="title"
    @modal-closed="handleModalClosed"
  />
</template>

<script>
import { computed } from "vue";
import CreateNewUserModal from "./CreateNewUserModal.vue";
import * as StateStore from "@/utils/store";

export default {
  name: "UsersWinnerList",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  components: {
    CreateNewUserModal,
  },
  data() {
    return {
      loading: false,
      searchQuery: "",
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() =>
        this.selectedItem === null ? "Crear Nueva " : "Editar "
      ),
      currentUser: null,
    };
  },
  mounted() {
    this.currentUser = StateStore.getters.getCurrentUser();
  },
  computed: {
    filteredAnswers() {
      if (this.items && Array.isArray(this.items)) {
        return this.items.filter((item) => {
          return (
            item.username
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            item.email
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.firstName
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.lastName
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase())
          );
        });
      }
      return [];
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers?.length / this.itemsPerPage);
    },
  },
  methods: {
    filterResults() {
      // Este método se llama al enviar el formulario, pero no es necesario hacer nada aquí
    },
    formatDate(isoString) {
      // Crear un objeto Date a partir de la cadena ISO
      const fecha = new Date(isoString);

      // Verificar si la fecha es válida
      if (isNaN(fecha.getTime())) {
        console.error("Fecha inválida:", isoString);
        return "Fecha inválida";
      }

      // Opciones de formato para la fecha y hora locales
      const opciones = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        // second: "2-digit",
        timeZoneName: "short",
      };

      // Formatear la fecha a la zona horaria local del usuario
      return fecha.toLocaleString(undefined, opciones);
    },
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);
    },
    openModal(item) {
      this.selectedItem = item;
      // console.log("selectedItem: ", this.selectedItem);
    },
    async deleteUser(id) {
      if (confirm("¿Está seguro de que desea eliminar el Jugador?")) {
        // console.log('El usuario confirmó la eliminación');
        await StateStore.actions.actionsDeleteUser({
          axios: this.axios,
          id: id,
        });
        location.reload();
      } else {
        // console.log('El usuario canceló la eliminación');
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllUserWinner() {
      await StateStore.actions.actionsGetAllUsersWinnerList({
        axios: this.axios,
      });
      this.items = StateStore.getters.getUsersWinnerList();
      this.loading = false;
    },
    async updatePrizeDeliveryStatus(user, newStatus) {
      this.loading = true;

      await StateStore.actions.actionsUpdatePrizeDeliveryStatus({
        axios: this.axios,
        id: user.id,
        prizeDeliveryStatus: newStatus,
      });
      this.getAllUserWinner();
    },
  },
  created() {
    this.getAllUserWinner();
  },
};
</script>
<style>
.long-text {
  white-space: pre-wrap;
  /* Permitir que el texto se divida en varias líneas */
  word-break: break-word;
  /* Dividir las palabras largas al final de línea*/
}

.text-success {
  color: #06b48a;
}

.text-danger {
  color: #ff7300;
}

.custom-select option {
  color: #4c4a68;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.4);
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #632639;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
