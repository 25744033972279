<template>
  <div ref="mymodal" class="modal fade createNewModal" id="createNewTopicModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">{{ title }} Tópico</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.name && showError }]">
                  <label class="form-label fw-semibold">Nombre</label>
                  <input v-model="item.name" type="text" class="form-control" placeholder="e.g. Nombre Tópico" />
                  <span v-if="!item.name && showError" class="text-danger">Este campo es requerido.</span>
                </div>
              </div>
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.educationalSections && showError }]">
                  <label class="form-label fw-semibold">Sección Educativa</label>
                  <select v-model="item.educationalSections" class="form-control">
                    <option v-for="(option, index) in educationalSections" :value="option.id" :key="index">
                      {{ option.name }}
                    </option>
                  </select>
                  <span v-if="!item.educationalSections && showError" class="text-danger">Seleccione una opción.</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label class="form-label fw-semibold">URL Video</label>
                  <input v-model="item.urlVideo" type="text" class="form-control"
                    placeholder="e.g. https://www.youtube.com/watch" />

                </div>
              </div>
              <div class="col-md-12">
                <div :class="['form-group mb-3', { 'has-error': !item.text && showError }]">
                  <label class="form-label fw-semibold">Texto del Tópico</label>
                  <textarea v-model="item.text" class="form-control textarea-spacing"
                    placeholder="Descripción"></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group mb-15 mb-md-25">
                  <label class="text-black fw-semibold mb-10">
                    Imagen del tópico
                  </label>
                  <input ref="fileInput" type="file" class="form-control shadow-none rounded-0 text-black"
                    @change="handleFileChange" />
                  <!-- Mostrar previsualización de la imagen -->
                  <small v-if="selectedFileName" class="text-muted">
                    <div v-if="imageUrls">
                      <img :src="imageUrls" alt="Imagen actual" class="img-thumbnail"
                        style="max-width: 100px; max-height: 100px; margin-top: 10px;">
                    </div>
                  </small>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label class="form-label fw-semibold">Imágenes para el carousel</label>
                  <ImageUpload ref="imageUpload" :itemId="item.id" :initialFiles="item.files"
                    @files-selected="handleFilesSelected" />
                </div>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
            <div v-if="success" class="alert alert-success mt-3">{{ message }}</div>
            <div class="modal-footer" style="display: flex; justify-content: space-between;">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"
                type="button" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
              <button type="submit"
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1">
                {{ title }} Tópico</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";
import * as StateStore from "@/utils/store";
import api from "@/api";

export default {
  name: "CreateNewTopicModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: Number,
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    ImageUpload,
  },
  data() {
    return {
      error: "",
      showError: false,
      success: false,
      message: '',
      selectedFileName: '',
      initialFileName: '',
      imageId: '',
      imageUrls: '',
      bucketName: 'cespt-store-prod',
      item: {
        id: null,
        name: '',
        text: '',
        urlVideo: '',
        educationalSections: null,
        image: null,
        files: [],
      },
      educationalSections: [],

    };
  },
  watch: {
    id(newId) {
      if (newId) {
        this.getTopicData(newId);
      }
      else if (newId === undefined) {
        this.item = {
          id: null,
          name: '',
          text: '',
          urlVideo: '',
          educationalSections: null,
          image: null,
          files: [],
        };
        this.selectedFileName = ''; // Limpiar el nombre del archivo si no está en modo edición
        this.imageUrls = '';
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    handleFilesSelected(files) {
      this.item.files = files;
    },
    async getData() {
      try {
        await StateStore.actions.actionsGetAllEducSectionsList({ axios: this.axios });
        this.educationalSections = StateStore.getters.getEducationalSectionsList();
        this.item.text = decodeURIComponent(this.item.text);
        this.item.urlVideo = decodeURIComponent(this.item.urlVideo);

      } catch (error) {
        console.error('Error al obtener secciones educativas:', error);
        this.error = 'Error al cargar las secciones educativas.';
      }
    },
    async getTopicData(id) {
      if (!id) return;
      try {
        await StateStore.actions.actionsGetArticles({ axios: this.axios, id });
        this.item = await StateStore.getters.getArticlesRetrieve();
        this.item.educationalSections = this.item.educationalSections ? this.item.educationalSections.id : null;
        this.item.text = decodeURIComponent(this.item.text);
        this.item.urlVideo = decodeURIComponent(this.item.urlVideo);

        // Asignar el nombre del archivo si existe una imagen asociada
        if (this.item.image && this.item.image.name) {
          this.selectedFileName = this.item.image.name;
          this.initialFileName = this.item.image.name;
          this.imageUrls = `https://${this.bucketName}.s3.amazonaws.com/Files/${this.item.image.name}`; // Asigna el URL de la imagen actual
        } else {
          this.selectedFileName = ''; // Si no hay imagen
          this.imageUrls = '';
        }
      } catch (error) {
        console.error('Error al obtener el tópico:', error);
        this.error = 'Error al cargar los datos del tópico.';
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Limpiar la URL de la imagen actual y agregar la nueva imagen seleccionada
        this.selectedFileName = file.name;
        this.imageUrls = URL.createObjectURL(file); // Mostrar la imagen seleccionada
      } else {
        // Si no se selecciona ninguna imagen, vaciar la previsualización
        this.imageUrls = '';
        this.selectedFileName = '';
        this.item.image = null;
      }

      this.item.image = event.target.files;
    },
    async submitForm() {
      this.showError = true;

      if (!this.item.name || !this.item.educationalSections) {
        this.error = 'Rellene los campos requeridos';
        return;
      }

      this.error = '';
      this.showError = false;
      this.success = true;

      await this.uploadImages();

      try {
        let fileIds = this.$refs.imageUpload.remainingInitialFileIds.map(id => Number(id));
        try {
          if (this.item.files && this.item.files.length > 0 && this.$refs.imageUpload.selectedFiles.length > 0) {
            const formData = new FormData();
            this.item.files.forEach((file) => {
              formData.append('file', file);
            });
            const response = await api.post('/upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            // Agregar los valores de response.data
            fileIds = fileIds.concat(response.data);

            this.message = 'Archivos subidos exitosamente';
          }
        } catch (e) {
          console.error('Error al subir los archivos:', e);
        }

        // Escapar caracteres especiales para su correcto manejo por la mutación
        const escapedText = encodeURIComponent(this.item?.text);
        const escapedUrl = encodeURIComponent(this.item?.urlVideo);

        if (!this.id) {
          // Creación
          await StateStore.actions.actionsCreateArticles({
            axios: this.axios,
            name: this.item.name,
            educationalSections: this.item.educationalSections,
            urlVideo: escapedUrl,
            text: escapedText,
            image: this.imageId,
            files: fileIds, // Asignar los IDs de los archivos subidos
          });
          this.message = 'Tópico creado con éxito...';
        } else {
          // Edición
          await StateStore.actions.actionsEditArticles({
            axios: this.axios,
            id: this.id,
            name: this.item.name,
            educationalSections: this.item.educationalSections,
            urlVideo: escapedUrl,
            text: escapedText,
            image: this.imageId,
            files: fileIds, // Asignar los IDs de los archivos subidos
          });
          this.message = 'Tópico modificado con éxito...';
        }

        this.$emit('topic-saved');
        // this.$refs.imageUpload.resetFileLimit();
        location.reload();

      } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        this.error = 'Ocurrió un error al procesar la solicitud.';
      }
    },
    async uploadImages() {
      if (!this.item.image) { return }
      else {
        this.imageId = this.item.image?.id;
      }

      // Si la imagen existe y no se ha modificado, terminar la ejecución
      if (this.initialFileName && this.initialFileName === this.selectedFileName) return;

      const formData = new FormData();
      Object.values(this.item.image).forEach((file) => {
        formData.append('file', file);
      });
      try {
        const response = await api.post('/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response !== null) {
          this.imageId = response.data[0]
        } else {
          console.error("Error al subir el ícono.");
        }
      } catch (error) {
        console.error("Error al subir el ícono:", error);
      }
    },
    handleModalHidden() {
      this.resetForm();
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    resetForm() {
      this.item = {
        id: null,
        name: '',
        text: '',
        urlVideo: '',
        educationalSections: null,
        image: null,
        files: [],
      };
      this.imageUrls = '';
      this.selectedFileName = '';
      this.error = '';
      this.success = false;
      this.showError = false;
      this.message = '';

      // Restablecer el estado del componente ImageUpload
      // this.$refs.imageUpload?.resetFileLimit();
      this.$refs.imageUpload?.resetInput();

      // Limpiar el campo de la imagen principal
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },
  },
  async created() {
    await this.getData();
    if (this.id) {
      this.getTopicData(this.id);
    }
  },
};
</script>

<style scoped>
.has-error input,
.has-error select {
  border-color: red;
}

.alert-success {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.alert-danger {
  background-color: red;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.textarea-spacing {
  line-height: 1.2;
  /* Espaciado entre líneas */
  min-height: 150px;
  max-height: 5000px;
  /* Altura máxima */
  overflow-y: auto;
  /* Mostrar barra de desplazamiento cuando exceda la altura */
}
</style>
