import { AxiosInstance } from 'axios';
// import * as StateStore from "@/utils/store"

export default class Groups {
    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                groupList {
                    id
                    name
                    permissions {
                        id
                        name
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log('los groups', response?.data?.data?.groupList)
        return response?.data?.data?.groupList;
    }

    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
                  groupRetrieve(id:${id}){
                    id
                    name
                    permissions{
                      id
                      name
                    }
                  }
                }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    // console.log("primer strike");
                    return null;
                } else if (error.request) {
                    // console.log("segudo strike");

                    return null;
                } else {
                    // console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.groupRetrieve
            ) {
                response.data.data.groupRetrieve.compressed = {
                    permission: response.data.data.groupRetrieve.permissions.map((permissions) => permissions),
                };
            }
            return response.data.data.groupRetrieve;
        }
    }

    // static async edit(
    //     axios: AxiosInstance,
    //     id: number,
    //     name: string,

    // ) {

    //     const response = await axios?.post(
    //         "/graphql/",
    //         `mutation{
    //               updateRole(newRoles:{
    //                 id: ${id}
    //                 name: "${name}"
    //               }){
    //                 groups{
    //                   id
    //                   name
    //                   permissions{
    //                     id
    //                     name
    //                   }
    //                 }
    //                 ok
    //                 errors{
    //                   field
    //                   messages
    //                 }
    //               }
    //             }`,
    //         {
    //             headers: {
    //                 "Content-Type": "application/graphql",
    //             },
    //         }
    //     ).catch(function (error) {
    //         if (error.response) {
    //             // console.log("primer strike");
    //             return Promise.resolve(null);
    //         } else if (error.request) {
    //             // console.log("segundo strike");
    //             return Promise.resolve(null);
    //         }
    //     });
    //     if (response && response.data) {
    //         if (response.data.data.updateRole.ok) {
    //             return response.data.data.updateRole.ok;
    //         } else if (response.data.data.updateRole.errors) {
    //             const errores = response.data.data.updateRole.errors;
    //             const mensajes: string[] = [];

    //             for (let i = 0; i < errores?.length; i++) {
    //                 const error = errores[i];
    //                 for (let j = 0; j < error.messages?.length; j++) {
    //                     mensajes.push(error.messages[j]);
    //                 }
    //             }
    //             // console.log("lo que tenemos: ", response.data.data.updateRole.errors);
    //             StateStore.actions.actionsSetErrorMessage(mensajes);
    //             return null;
    //         } else if (response.data.errors) {
    //             // console.log("lo que tenemos: ", response.data.errors);
    //             StateStore.actions.actionsSetErrorMessage(response.data.errors);
    //             return null;
    //         }
    //     }
    //     else {
    //         return null;
    //     }
    // }

    // static async create(
    //     axios: AxiosInstance,
    //     name: string,

    // ) {

    //     const response = await axios?.post(
    //         "/graphql/",
    //         `mutation{
    //           createRoles(newRoles:{
    //             name: "${name}"
    //           }){
    //             ok
    //             errors{
    //               field
    //               messages
    //             }
    //             groups{
    //               id
    //               name
    //               permissions{
    //                 id
    //                 name
    //               }
    //               userRole{
    //                 id
    //                 username
    //                 email
    //               }
    //             }
    //           }
    //         }`,
    //         {
    //             headers: {
    //                 "Content-Type": "application/graphql",
    //             },
    //         }
    //     ).catch(function (error) {
    //         if (error.response) {
    //             // console.log("primer strike");
    //             return Promise.resolve(null);
    //         } else if (error.request) {
    //             // console.log("segundo strike");
    //             return Promise.resolve(null);
    //         }
    //     });
    //     if (response && response.data) {
    //         if (response.data.data.createRoles.ok) {
    //             return response.data.data.createRoles.ok;
    //         } else if (response.data.data.createRoles.errors) {
    //             const errores = response.data.data.createRoles.errors;
    //             const mensajes: string[] = [];

    //             for (let i = 0; i < errores?.length; i++) {
    //                 const error = errores[i];
    //                 for (let j = 0; j < error.messages?.length; j++) {
    //                     mensajes.push(error.messages[j]);
    //                 }
    //             }
    //             // console.log("lo que tenemos: ", response.data.data.createRoles.errors);
    //             StateStore.actions.actionsSetErrorMessage(mensajes);
    //             return null;
    //         } else if (response.data.errors) {
    //             // console.log("lo que tenemos: ", response.data.errors);
    //             StateStore.actions.actionsSetErrorMessage(response.data.errors);
    //             return null;
    //         }
    //     }
    //     else {
    //         return null;
    //     }
    // }
}