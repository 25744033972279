<template>
  <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box">
    <div
      class="card-body pb-0 letter-spacing d-flex justify-content-between align-items-start"
      style="min-height: 205px"
    >
      <div class="p-12">
        <span
          class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis"
        >
          Ganadores del Kit
        </span>
        <!-- Cantidad total de ganadores entre Rosarito y Tijuana -->
        <h1 class="fw-black mb-12 lh-1 mt-30">{{ totalWinners }}</h1>

        <!-- Lista dinámica de ciudades con el número de ganadores -->
        <ul class="list ps-0 mb-0 list-unstyled mt-15">
          <li
            class="text-muted position-relative fw-medium"
            v-for="(city, index) in cityWinners"
            :key="index"
          >
            {{ city.name }} -
            <span class="text-black fw-bold">{{ city.count }}</span>
          </li>
        </ul>
      </div>

      <!-- Gráfico con los datos de ganadores -->
      <apexchart
        type="donut"
        height="200"
        :options="earningChart"
        :series="cityWinners.map((city) => city.count)"
      >
      </apexchart>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";

export default defineComponent({
  name: "ExpectedEarnings",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      winners: [] as any[],
      cityWinners: [] as any[],
      totalWinners: 0,
      earningChart: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#9C9AB6",
          },
          markers: {
            offsetX: -2,
            offsetY: 1,
          },
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
        colors: [] as string[],
      },
    };
  },
  methods: {
    async getAllUserWinner() {
      // Obtén todos los ganadores desde el store
      await StateStore.actions.actionsGetAllUsersWinnerList({
        axios: this.axios,
      });
      this.winners = StateStore.getters.getUsersWinnerList();

      // Procesar los datos para obtener la cantidad de ganadores por ciudad
      const cityCounts: Record<string, number> = {};
      const cityColors: Record<string, string> = {
        "Playas de Rosarito": "#05906E", // Color para Playas de Rosarito
        Tijuana: "#148AB4", // Color específico para Tijuana
      };

      this.winners?.forEach((user) => {
        const userCity = user.city?.name;
        if (userCity === "Tijuana" || userCity === "Playas de Rosarito") {
          cityCounts[userCity] = (cityCounts[userCity] || 0) + 1;
          this.totalWinners += 1;
        }
      });

      // Convertir cityCounts en un array para mostrar en el template
      this.cityWinners = Object.entries(cityCounts)
        .map(([name, count]) => ({
          name,
          count,
          color: cityColors[name] || "#999999",
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Ordenar alfabéticamente por nombre

      // Actualizar los colores del gráfico basados en los colores de cada ciudad
      this.earningChart.colors = this.cityWinners.map((city) => city.color);
    },
  },
  created() {
    this.getAllUserWinner();
  },
});
</script>
